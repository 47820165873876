
import { defineComponent } from "vue";
import AutoComplete from "primevue/autocomplete";
import store from "@/store";
import Utils from "@/utility/utils";

// Services
import CustomerService from "@/services/CustomerService";
import PartsService from "@/services/PartsService";
import ProspectService from "@/services/ProspectService";

const customerService = new CustomerService();
const prospectService = new ProspectService(
  process.env.VUE_APP_ABSTRACTION_API,
);
import { mapActions, mapGetters, mapState } from "vuex";

const typingDelay = 1500;

export default defineComponent({
  name: "Search",
  components: {
    AutoComplete,
  },
  computed: {
    ...mapState(["customerInquiry"]),
    ...mapGetters({
      getAllCustomers: "customer/getAllCusts",
      getFilter: "session/getFilter",
      filterInactiveCustomers: "mrkControl/filterInactiveCustomers",
      getSelectedCoCode: "customerInquiry/getSelectedCoCode",
      getScanSettings: "scan/getScanSettings",
      getRegister: "pos/getRegister",
    }),
    getDelay(): number {
      return this.quickScanMode ? 0 : typingDelay;
    },
  },
  created() {
    this.customers = this.getAllCustomers.cust_items;
  },
  methods: {
    ...mapActions({
      fetchAllCustomers: "customer/getAllCustomers",
      fetchCustomer: "customerInquiry/getCustomer",
      fetchParts: "pos/fetchParts",
      getMrkControl: "mrkControl/getMrkControl",
    }),
    lookup($event: any) {
      switch (this.file) {
        case "CUST":
          this.getCust($event);
          break;
        case "Bill":
          this.$emit("filterCust", $event);
          break;
        case "NEWSALES":
          this.$emit("filterCust", $event);
          break;
        case "Invoice":
          this.$emit("setCust", $event);
          break;
        case "PARTS":
          this.$emit("filterPart", $event);
          break;
        case "PRICE":
          this.$emit("filterPrice", $event);
          break;
        case "Sale":
          this.$emit("filterCust", $event);
          break;
        case "Inventory":
          this.getPart($event);
          break;
        case "PROSPECT":
          this.$emit("filter", $event);
          break;
        case "Account":
          this.$emit("filterCust", $event);
          break;
        case "Pos":
          this.$emit("filterCust", $event);
          break;
        case "FSO":
          this.$emit("filterCust", $event);
          break;
        default:
          break;
      }
    },
    handleEnter(event: any) {
      event.enterKeyPressed = true;
      event.value = this.input;
      this.input = "";
      this.searchItem(event); // Trigger search logic directly
    },
    handleChange(event: any) {
      // CO 3/5/24 Autocomplete will force a change to an object if there is only one value in the list
      // This override will force the value to be a string
      const isString = typeof event.value === "string";
      if (!isString) {
        this.input = "";
        return;
      }

      this.skipSearch = false;

      const scanValue = this.validateScan();
      if (scanValue) {
        event.value = scanValue;
        event.validScan = true;
        event.isScanned = true;
        this.searchItem(event);
        this.skipSearch = true;
      }

      if (this.quickScanMode) {
        clearTimeout((this.$refs.autocomplete as any).timeout);
      }
    },
    searchItem(element: any) {
      // check to see if the minimum character count has been reached
      const query = element.value
        ? element.value
        : element.query
          ? element.query
          : this.input;

      if (
        (element.query?.length < this.minimumCharacters &&
          element.enterKeyPressed) ||
        (this.quickScanMode &&
          !element.validScan &&
          !element.enterKeyPressed) ||
        this.skipSearch
      ) {
        this.skipSearch = false;
        (this.$refs.autocomplete as any).searching = false;
        return;
      }
      if (element.enterKeyPressed) {
        this.skipSearch = true;
      }
      clearTimeout((this.$refs.autocomplete as any)?.timeout);
      // We use this emit to send a flag to indicate that the component started a search
      // and we can set the flag to false when the component sends the new data
      this.$emit("isSearching", true);

      // this.input is being cleared on the handleEnter method to address a scan concern.
      // For searches that do not pass a search function, we need to set the input to the query to ensure the search is done with the correct value.

      if (!this.searchFunction) {
        this.input = query;
      }

      switch (this.file) {
        case "CUST":
          this.searchLookup();
          break;
        case "Bill":
          this.searchLookup();
          break;
        case "NEWSALES":
          this.searchLookup();
          break;
        case "Invoice":
          this.searchLookup();
          break;
        case "Sale":
          this.searchLookup();
          break;
        case "Pos":
          this.searchLookup();
          break;
        case "PARTS":
          this.lookupPart();
          break;
        case "Inventory":
          this.lookupPart();
          break;
        case "PROSPECT":
          this.lookupProspect();
          break;
        case "Account":
          this.searchLookup();
          break;
        case "FSO":
          this.searchLookup();
          break;
        default: {
          const searchInput = query;
          this.searchFunction &&
            this.searchFunction(searchInput, this, element);
          break;
        }
      }
    },
    getCust(element: any) {
      store
        .dispatch("customerInquiry/getCustomer", {
          id: element.value.cust_id,
          Client: store.getters["session/getClient"],
          correls:
            "saleopp_ids soquote_ids so_ids ticket_ids ar_ids rma_ids log_ids highest_ar_bal avg_ar_bal",
        })
        .then((response) => {
          this.input = "";
        });
    },
    getPart(element: any) {
      store
        .dispatch("inventoryInquiry/fetchPart", {
          partId: element.value.part_no,
          client: store.getters["session/getClient"],
          correls: "um",
        })
        .then((response) => {
          this.input = "";
        });
    },
    lookupProspect() {
      prospectService.lookup(this.input).then((response: any) => {
        this.options = response.prospect_items;
      });
    },
    async searchLookup() {
      // Don't allow search if the input is empty
      if (!this.input) {
        return;
      }
      const filterCustomers = this.filterInactiveCustomers ? "Y" : "";
      if (this.file === "CUST" || this.file === "POS") {
        const filterCustomers = this.filterInactiveCustomers ? "Y" : "";
        const reps = this.getFilter("CUST", "REP");
        const searchInput = this.input;
        this.fetchAllCustomers({
          selection: this.input,
          correls: "rep_name",
          rep: reps,
          activeOnly: filterCustomers,
          reg: this.getRegister?.reg_id,
        }).finally(() => {
          (this.$refs.autocomplete as any).searching = false;
          this.$emit("customerSearch", searchInput);
        });
      } else {
        customerService
          .getAllCustomers({
            selection: this.input,
            correls: "rep_name",
            activeOnly: filterCustomers,
          })
          .then((response: any) => {
            this.options = response.cust_items.sort((a: any, b: any) =>
              a.name.localeCompare(b.name),
            );
          })
          .finally(() => {
            (this.$refs.autocomplete as any).searching = false;
          });
      }
    },
    lookupPart() {
      if (this.currentView == "Pos") {
        const partSearch = this.input;
        this.fetchParts({ selection: this.input }).finally(() => {
          this.$emit("filterPart", partSearch);
          (this.$refs.autocomplete as any).searching = false;
        });
      } else {
        const service = new PartsService();
        service
          .fetchPartsOptions(
            "part_no",
            this.input,
            store.getters["session/getClient"],
          )
          .then((response: any) => {
            if (
              response.parts_items.length === 1 &&
              this.file === "Inventory"
            ) {
              this.getPart({
                value: {
                  ...response.parts_items[0],
                },
              });
              this.options = [];
            } else {
              this.options = response.parts_items;
            }
          });
      }
    },
    isScanMode() {
      return (
        this.scannerMode &&
        (this.getScanSettings ? this.getScanSettings.scanMode : true)
      );
    },
    validateScan() {
      // For search scan is available but there must be a prefix/suffix or else the search will fire after every character press
      if (
        this.isScanMode() &&
        (this.getScanSettings.prefix || this.getScanSettings.suffix)
      ) {
        const validString = Utils.validateScanInput(this.input, true);
        if (validString) {
          this.input = "";
          return validString;
        }
      }
      return "";
    },
  },
  data() {
    return {
      input: "",
      options: [],
      isLoadingCustomers: false,
      customers: [],
      skipSearch: false,
    };
  },
  props: {
    label: String,
    file: String,
    placeHolder: String,
    currentView: String,
    leftIcon: {
      type: Boolean,
      default: false,
    },
    quickScanMode: {
      type: Boolean,
      default: false,
    },
    scannerMode: {
      type: Boolean,
      default: false,
    },
    minimumCharacters: {
      type: Number,
      default: 2,
    },
    searchOptions: {} as any,
    partStatus: Array,
    searchFunction: Function,
  },
});
