
import { defineComponent } from "@vue/runtime-core";
import { mapActions, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Dialog from "primevue/dialog";
import Chips from "primevue/chips";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import Button from "primevue/button";

export default defineComponent({
  name: "Email Attachment",
  components: {
    Dialog,
    Chips,
    InputText,
    Textarea,
    Button,
  },
  props: {
    show: Boolean,
    attachmentId: String,
    fileName: String,
  },
  emits: ["hide", "onEmail"],
  computed: {
    ...mapGetters({
      getMailControls: "control/getMailControls",
      getClient: "session/getClient",
    }),
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      to: { required },
    };
  },
  created() {
    this.initControls();
    this.mailControls();
  },
  data() {
    return {
      showEmailModal: false,
      modalAttachment: this.attachmentId || "",
      modalFile: this.fileName || "",
      html: false,
      secure: false,
      from: [""],
      to: null,
      cc: null,
      bcc: null,
      subject: null,
      body: null,
      isSubmitted: false,
      isSendingEmail: false,
    };
  },
  methods: {
    ...mapActions({
      emailAttachment: "customerInquiry/emailAttachment",
      fetchControls: "control/fetchControl",
    }),
    hideDialog() {
      this.showEmailModal = false;
      this.modalAttachment = "";
      this.modalFile = "";
      this.html = false;
      this.secure = false;
      this.from = [""];
      this.to = null;
      this.cc = null;
      this.bcc = null;
      this.subject = null;
      this.body = null;
      this.mailControls();
    },
    initControls() {
      if (this.getMailControls == null) {
        this.fetchControls({
          id: "MAIL",
          procedure: "MAIL.CONTROL",
          filename: "CONTROL",
          Client: this.getClient,
        }).then(() => {
          this.mailControls();
        });
      }
    },
    mailControls() {
      if (this.getMailControls) {
        let controls = this.getMailControls;
        this.from[0] = controls.m3services_sender_address;
        this.secure = controls.m3services_secure == "Y" ? true : false;
        this.html = controls.m3services_html == "Y" ? true : false;
      }
    },
    async email() {
      this.isSubmitted = true;
      this.isSendingEmail = true;

      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        this.emailAttachment({
          From: this.from,
          To: this.to,
          Cc: this.cc,
          Bcc: this.bcc,
          Subject: this.subject,
          Body: this.body,
          Html: this.html,
          Secure: this.secure,
          AttachmentId: this.modalAttachment,
          Client: this.getClient,
        })
          .then(() => {
            this.isSendingEmail = false;
            this.mailControls();
            this.hideDialog();
            this.$emit("onEmail", { success: true });
          })
          .catch(() => {
            this.isSubmitted = false;
            this.isSendingEmail = false;
            this.$emit("onEmail", { success: false });
          });
      }
    },
  },
  watch: {
    show(newShow) {
      this.showEmailModal = newShow;
      if (newShow && this.attachmentId && this.fileName) {
        this.modalAttachment = this.attachmentId || "";
        this.modalFile = this.fileName || "";
      }
    },
  },
});
