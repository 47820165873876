import { AxiosInstance } from "axios";
import axios from "axios";
import store from "../store";
import ClientAPI from "./ClientAPI";

export default class BatchService {
  private readonly client: AxiosInstance;
  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }
  async createRecords(payload: any) {
    const user = store.getters["session/getUser"].user_id || "WEBUSER";
    return ClientAPI.Post(
      `batch/${payload.filename}?user=${user}&meta=${payload.meta}`,
      payload.newRecord,
    );
  }

  async updateRecords(payload: any) {
    const user = store.getters["session/getUser"].user_id || "WEBUSER";
    const { filename, newRecords, oldRecords } = payload;
    return ClientAPI.Put(
      `batch/${filename}?user=${user}&meta=${payload.meta}`,
      { newRecords, oldRecords },
    );
  }
}
