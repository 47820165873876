
import { defineComponent } from "vue";
import Dialog from "primevue/dialog";
import TextArea from "primevue/textarea";
import Dropdown from "primevue/dropdown";
import FileUpload from "primevue/fileupload";
import Button from "primevue/button";
import { mapActions, mapGetters, mapState } from "vuex";

import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default defineComponent({
  name: "Add Attachment Dialog",
  components: {
    Dialog,
    TextArea,
    Dropdown,
    FileUpload,
    Button,
  },
  props: {
    show: Boolean,
    isLoadingAttachment: Boolean,
  },
  emits: ["hide", "onUploadAttachment"],
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      selectedFolder: {
        required,
      },
    };
  },
  created() {
    this.initControls();
  },
  data() {
    return {
      isHidden: false,
      disableUpload: false,
      fileName: "",
      mimeType: "",
      file: "" as string | null,
      description: "",
      selectedFolder: null,
      isUploading: false,
    };
  },
  computed: {
    ...mapState(["control"]),
    ...mapGetters({
      getFolderNames: "control/getFolderNames",
    }),
  },
  methods: {
    ...mapActions({
      fetchControl: "control/fetchControl",
    }),
    uploadAttachment(event: any) {
      this.disableUpload = true;
      const file = event.files[0];
      this.fileName = event.files[0].name;
      this.mimeType = event.files[0].type;
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        this.file = reader.result as string;
      };
    },
    clearDialog() {
      this.fileName = "";
      this.description = "";
      this.disableUpload = false;
      this.selectedFolder = null;
      (this.$refs.fileUploader as any)?.clear();
    },
    async saveAttachment() {
      const isFormCorrect = await this.v$.selectedFolder.$validate();
      if (this.file != "" && isFormCorrect) {
        this.$emit("onUploadAttachment", {
          doc_name: this.fileName,
          doc: this.file,
          mime_type: this.mimeType,
          folder: this.selectedFolder,
          desc: this.description,
        });
      }
    },
    initControls() {
      let folders = this.getFolderNames;
      const CONTROLS = [
        {
          id: "ATTACHMENT",
          procedure: "ATTACHMENT.CONTROL",
          filename: "CONTROL",
          fieldnames: "folder_name",
          getter: this.getFolderNames,
        },
      ];
      if (this.getFolderNames == null) {
        CONTROLS.forEach((element) => {
          if (element.getter?.length === 0 || element.getter == null) {
            this.fetchControl(element).then(() => {
              if (folders != null && folders.length > 0) {
                folders = this.getFolderNames;
                this.selectedFolder = folders[0].folder_name;
              }
            });
          }
        });
      }
    },
  },
  watch: {
    show(newShow) {
      this.isHidden = newShow;
    },
  },
});
