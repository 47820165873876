import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "collapsible-section" }
const _hoisted_2 = { class: "col-1 divider-header p-0 mr-2 font-medium" }
const _hoisted_3 = { class: "flex align-items-center m-0 pr-0 ml-2" }
const _hoisted_4 = { class: "collapsible-section-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Divider = _resolveComponent("Divider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "flex p-0 m-0 pl-1 pr-1 col-12 justify-content-between align-items-center my-divider",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
    }, [
      _createElementVNode("small", _hoisted_2, _toDisplayString(_ctx.header), 1),
      _createVNode(_component_Divider, { type: "dashed" }),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("i", {
          class: _normalizeClass(_ctx.getSectionIconClass)
        }, null, 2)
      ])
    ]),
    _createVNode(_Transition, { name: "slide" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "content")
        ], 512), [
          [_vShow, _ctx.isOpen]
        ])
      ]),
      _: 3
    })
  ]))
}