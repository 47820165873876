<template>
  <Dialog
    v-model:visible="isHidden"
    :style="{ width: '350px' }"
    :header="header"
    :modal="true"
    :draggable="false"
    class="p-fluid text-center"
    @hide="
      () => {
        $emit('hide', isHidden);
      }
    "
  >
    <div class="flex confirmation-content">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
      <small class="text-base font-bold">{{ message }}</small>
    </div>
    <template #footer>
      <Button
        label="No"
        icon="pi pi-times"
        class="p-button-text"
        @click="
          () => {
            cancel();
          }
        "
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        class="p-button-danger"
        @click="
          () => {
            agree();
          }
        "
      />
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import Button from "primevue/button";

export default {
  name: "ConfirmChangesDialog",
  components: {
    Dialog,
    Button,
  },
  props: {
    show: Boolean,
    message: {
      type: String,
      default() {
        return "Are you sure you want to delete this record permanently?";
      },
    },
    header: {
      type: String,
      default() {
        return "Confirm Changes";
      },
    },
  },
  emits: ["hide"],
  data() {
    return {
      isHidden: false,
      dialog: false,
      resolve: null,
      reject: null,
    };
  },
  methods: {
    open() {
      this.isHidden = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.isHidden = false;
    },
    cancel() {
      this.resolve(false);
      this.isHidden = false;
    },
  },
};
</script>
