
import { defineComponent } from "vue";

import Dialog from "primevue/dialog";

export default defineComponent({
  name: "Quick Ticket Dialog",
  components: {
    Dialog,
  },
  emits: ["hide"],
  props: {
    show: Boolean,
  },
  data() {
    return {
      isHidden: false,
    };
  },
  watch: {
    show(newShow) {
      this.isHidden = newShow;
    },
  },
});
