
import { defineComponent } from "vue";
import Divider from "primevue/divider";

export default defineComponent({
  name: "CollapsibleSection",
  components: {
    Divider,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    header: {
      type: String,
      default: "Section Title",
    },
  },
  data() {
    return {
      open: true,
    };
  },
  mounted() {
    this.open = this.isOpen;
  },
  updated() {
    this.open = this.isOpen;
  },
  computed: {
    getSectionIconClass(): string {
      return this.open ? "pi pi-chevron-down" : "pi pi-chevron-right";
    },
  },
  emits: ["update:isOpen"],
  methods: {
    toggle() {
      this.$emit("update:isOpen");
    },
  },
});
